<template>
  <div>
    <p class="title" v-if="!titshow"><el-link type="primary" :underline="false" style="fontSize:18px"
        @click="goback(1)">{{ this.$route.params.name }}商户商品</el-link></p>
    <p class="title" v-if="titshow">
      <el-link type="primary" :underline="false" style="fontSize:18px" @click="goback(1)">{{ this.$route.params.name
      }}商户商品</el-link>>>
      <!-- <el-link type="primary" :underline="false" style="fontSize:18px" @click="goback(2)">{{this.$route.params.b}}</el-link> -->
      {{ this.$route.params.a }}
    </p>
    <div class="agent_search">
      <label class="lab" for="searchname">商品名称</label>
      <el-input clearable name="searchname" placeholder="请输入商品名称" class="goods_search" v-model="goodsname"></el-input>
      <label class="lab" for="searchname">商品编号</label>
      <el-input clearable name="searchname" placeholder="请输入商品编号" class="goods_search" v-model="goodscode"></el-input>
      <el-button type="primary" style="marginLeft:10px" @click="getlist(1)">搜索</el-button>
      <el-button type="primary" @click="reset">重置</el-button>
    </div>
    <div style="marginTop:100px">
      <p style="text-align: right;padding-right: 20px;">
        <!-- 昊天平台上架更新功能 -->
        <el-button type="primary" size="small" @click="replace_price">更新邀购电子进价</el-button>
        <el-button type="primary" size="small" @click="timing1 = !timing1">定时上架</el-button>
        <el-button type="primary" size="small" @click="timing2 = !timing2">定时改价</el-button>
      </p>
      <el-table max-height="650" :data="tableData" border style="width: 99%;margin:0 auto">
        <el-table-column fixed prop="good_coding" label="商品编码" width="100">
        </el-table-column>
        <el-table-column prop="good_name" label="商品名称" width="230">
        </el-table-column>
        <el-table-column prop="purchase_price" width="150" label="进价">
          <template slot-scope="scope">
            <el-input v-model="scope.row.purchase_price" style="width:120px" @blur="purchase_price(scope.row)"
              @focus="oldprice = scope.row.purchase_price"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="商品销售价" width="150">
          <template slot-scope="scope">
            <!-- <el-button type="text" @click="chanprice(scope.row)">修改</el-button>  -->
            <el-input v-model="scope.row.price" style="width:120px" @blur="pricechange(scope.row)"
              @focus="oldprice = scope.row.price"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="官方价" width="150">
          <template slot-scope="scope">
            <el-input v-model="scope.row.official_price" style="width:120px" @blur="official_price(scope.row)"
              @focus="oldprice = scope.row.official_price"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="supplier_str" label="商品归属" width="100">
        </el-table-column>
        <el-table-column label="商品销售价状态" width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.price_status == 1">已配置</span>
            <span v-else>未配置</span>
          </template>
        </el-table-column>
        <el-table-column label="定时改价时间和价格" align="center" width="210" show-overflow-tooltip>
          <template slot-scope="scope" v-if="scope.row.change_time">
            <span style="color:blue">{{ scope.row.change_time }}</span>,<span style="color:red">{{ scope.row.change_price
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- <el-dropdown trigger="click" placement="bottom">
                <span class="el-dropdown-link">
                  操作<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown" >
                  <el-dropdown-item style="text-align:center" @click.native="setautoissue(scope.row)">定时上架</el-dropdown-item>
                 <el-dropdown-item style="text-align:center" @click.native="toupdate(scope.row)">定时改价</el-dropdown-item>
                 <el-dropdown-item style="text-align:center"  @click.native="changeload(scope.row)">切换渠道</el-dropdown-item>
                </el-dropdown-menu>
                </el-dropdown> -->
            <!-- <el-button type="text" @click="edit(scope.row)">编辑</el-button> -->
            <el-button type="text" @click="changeload(scope.row)">切换渠道</el-button>
          </template>
        </el-table-column>
        <el-table-column label="定时上架" v-if="timing1">
          <template slot-scope="scope">
            <el-button type="text" @click="setautoissue(scope.row)">定时上架</el-button>
          </template>
        </el-table-column>
        <el-table-column label="定时改价" v-if="timing2">
          <template slot-scope="scope">
            <el-button type="text" @click="toupdate(scope.row)">定时改价</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="fy" style="margin-top:5px" background prev-text='上一页' next-text='下一页'
        layout="total , prev, pager, next, jumper" @current-change="current_change" :total="total" :page-size="pagesize"
        :current-page.sync="currentPage">
      </el-pagination>
    </div>
    <el-dialog title="切换渠道" center :visible.sync="dialogVisible" width="600px">
      <div class="agent_searchs">
        <label class="lab" for="searchname">接口名称</label>
        <el-input clearable name="searchname" placeholder="请输入接口名称" class="goods_searchs"
          v-model="Interfacename"></el-input>
        <el-button type="primary" size="small" @click="search" style="marginLeft:10px">搜索</el-button>
        <el-button type="primary" size="small" @click="resets">重置</el-button>
      </div>
      <div>
        <el-table :data="InterfaceData" border style="width: 100%;margin:10px  auto 0 auto">
          <el-table-column width="120">
            <template slot-scope="scope">
              <el-radio v-model="radio" :label="scope.row.id" @change.native="changein(scope.row)">{{
                scope.row.good_coding }}</el-radio>
            </template>
          </el-table-column>
          <el-table-column prop="good_name" label="商品名称" width="200">
          </el-table-column>
          <el-table-column prop="s_name" label="接口名称">
          </el-table-column>
          <!-- <el-table-column
                    prop="good_coding"
                    label="商品编码">
                  </el-table-column> -->
          <el-table-column prop="purchase_price" label="接口进价">
          </el-table-column>
        </el-table>
        <el-pagination style="margin-top:5px;textAlign:center" background prev-text='上一页' next-text='下一页'
          layout="total , prev, pager, next, jumper" @current-change="current_changes" :total="totals"
          :page-size="pagesizes" :current-page.sync="currentPages">
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="changing">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="setissuedialog" width="800px">
      <span slot="title" style="font-size:16px;text-align:left;">
        设置商品定时上架
      </span>
      <p style="height:60px;margin:0;text-align:left;">
        <label style="display:inline-block;width:100px;text-align: right;">商品名称：</label><span name="name">{{
          row_value.good_name }}</span>
      </p>
      <p style="height:60px;margin:0;text-align:left;" class="timelist">
        <label style="display:inline-block;width:100px;text-align: right;">定时周期：</label>
        <el-checkbox v-model="monday" label="每周一" border size="small"></el-checkbox>
        <el-checkbox v-model="tuesday" label="每周二" border size="small"></el-checkbox>
        <el-checkbox v-model="wednesday" label="每周三" border size="small"></el-checkbox>
        <el-checkbox v-model="thursday" label="每周四" border size="small"></el-checkbox>
        <el-checkbox v-model="friday" label="每周五" border size="small"></el-checkbox>
        <el-checkbox v-model="saturday" label="每周六" border size="small"></el-checkbox>
        <el-checkbox v-model="sunday" label="每周日" border size="small"></el-checkbox>
      </p>
      <p style="height:60px;margin:0;text-align:left;">
        <label style="display:inline-block;width:100px;text-align: right;">定时上架时间：</label>
        <el-time-picker is-range v-model="issuetime" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"
          placeholder="选择时间范围" @change="sjtimes" value-format="HH:mm:ss" :disabled="sjtime">
        </el-time-picker>
      </p>
      <p style="height:60px;margin:0;text-align:left;">
        <label style="display:inline-block;width:100px;text-align: right;">定时维护时间：</label>
        <el-time-picker is-range v-model="issuetimes" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"
          placeholder="选择时间范围" value-format="HH:mm:ss" :disabled="whtime" @change="whtimes()">
        </el-time-picker>
      </p>
      <p style="height:60px;margin:0;text-align:left;">
        <label style="display:inline-block;width:100px;text-align: right;">定时开关：</label>
        <el-switch style="padding-right:10px" v-model="issuetimeswitch" active-color="#13ce66" inactive-color="#ff4949"
          :active-value=1 :inactive-value=2>
        </el-switch>
        <span v-if="issuetimeswitch == 1">开</span>
        <span v-else>关</span>
      </p>
      <el-button type="success" @click="setissuetime()">提交</el-button>
    </el-dialog>
    <!-- 定时改价 -->
    <el-dialog :visible.sync="updatedialog" width="700px">
      <span slot="title" style="font-size:16px">
        商品名称：{{ info_name }}
      </span>
      <!-- <p style="text-align:center;height:50px;margin:0">
              <label style="display:inline-block;width:130px;text-align: right;">进价：</label><el-input name="code" v-model="row_info.purchase_price" placeholder="请输入商品进价" type="number" style="width:210px"></el-input>
            </p> -->
      <p style="text-align:center;height:50px;margin:0">
        <label style="display:inline-block;width:130px;text-align: right;">销售价：</label><el-input v-model="row_info.price"
          placeholder="请输入商品销售价" type="number" min="0" style="width:210px"></el-input>
      </p>
      <!-- <p style="text-align:center;height:50px;margin:0">
              <label style="display:inline-block;width:130px;text-align: right;">价格上限：</label><el-input v-model="row_info.price_cap" placeholder="请输入商品价格上限" type="number" min="0" style="width:210px"></el-input>
            </p> -->
      <!-- <p style="text-align:center;height:50px;margin:0">
              <label style="display:inline-block;width:130px;text-align: right;">官方价格：</label><el-input v-model="row_info.official_price" placeholder="请输入商品价格上限" type="number" min="0" style="width:210px"></el-input>
            </p> -->
      <p style="text-align:center;height:50px;margin:0">
        <label style="display:inline-block;width:130px;text-align: right;">更新时间：</label>
        <el-date-picker v-model="row_info.change_time" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择日期时间">
        </el-date-picker>
      </p>
      <el-button type="success" @click="update_price()">提交</el-button>
    </el-dialog>
  </div>
</template>

<script>
export default {
  inject: ['chanlist'],
  data() {
    return {
      oldprice: "",
      timing1: false,
      timing2: false,
      updatedialog: false,
      info_name: "",
      row_info: {
        mid: "",
        gid: "",
        price: "",
        purchase_price: "",
        official_price: "",
        change_time: ""
      },
      issuetimes: [],
      issuetime: [],
      setissuedialog: false,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
      issuetimeswitch: 2,
      whtime: false,
      sjtime: false,
      timetype: 2,
      row_value: "",
      row_val: "",
      titshow: false,
      id: "",
      radio: "",
      InterfaceData: [],
      Interfacename: "",
      dialogVisible: false,
      currentPage: 1,
      pagesize: 10,
      total: 0,
      currentPages: 1,
      pagesizes: 10,
      totals: 0,
      goodsname: "",
      goodscode: "",
      tableData: []
    }
  },
  mounted() {
    this.chanlist("1-3", '1')
    if (this.$route.params.mid == undefined) {
      this.$router.push("/homes/goods_config")
    }
    // console.log(this.$route.params);
    if (this.$route.params.g_name == undefined) {
      this.goodsname = ''
      this.titshow = true
    } else {
      this.titshow = false
      this.goodsname = this.$route.params.g_name
    }
    this.getlist()
  },
  methods: {
    //昊天平台上架更新功能
    replace_price() {
      this.$axios.post('/supplier/merchant/update.yaogou.price').then(res => {
        if (res.data.code == 20000) {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.getlist()
        }
      })
    },
    //官方价
    official_price(row) {
      let reg = /^([0-9][0-9]*)+(\.[0-9]{1,4})?$/
      if (!reg.test(row.price)) {
        this.$message({
          type: "warning",
          message: "请输入正确的商品官方价"
        })
        this.getlist()
      } else {
        if (parseFloat(row.official_price) == parseFloat(this.oldprice)) {
          this.getlist()
          return
        }
        this.$axios.post('/supplier/merchant/good.change.official.price', { gid: row.id, official_price: row.official_price, mid: this.$route.params.mid }).then(res => {
          if (res.data.code == 20000) {
            this.$message({
              type: "success",
              message: res.data.message
            })
            this.getlist()
          } else {
            this.$message({
              type: "warning",
              message: res.data.message
            })
            this.getlist()
          }
        })
      }
    },
    //进价
    purchase_price(row) {
      let reg = /^([0-9][0-9]*)+(\.[0-9]{1,4})?$/
      if (!reg.test(row.price)) {
        this.$message({
          type: "warning",
          message: "请输入正确的商品进价"
        })
        this.getlist()
      } else {
        if (parseFloat(row.purchase_price) == parseFloat(this.oldprice)) {
          this.getlist()
          return
        }
        this.$axios.post('/supplier/merchant/good.change.purchase.price', { gid: row.id, purchase_price: row.purchase_price, mid: this.$route.params.mid }).then(res => {
          if (res.data.code == 20000) {
            this.$message({
              type: "success",
              message: res.data.message
            })
            this.getlist()
          } else {
            this.$message({
              type: "warning",
              message: res.data.message
            })
            this.getlist()
          }
        })
      }
    },
    //定时上架
    setissuetime() {
      var start_time;
      var end_time;
      if (this.timetype == 1) {
        if (this.issuetime) {
          start_time = this.issuetime[0]
          end_time = this.issuetime[1]
        } else {
          start_time = ''
          end_time = ''
        }

      } else {
        if (this.issuetimes) {
          start_time = this.issuetimes[0]
          end_time = this.issuetimes[1]
        } else {
          start_time = ''
          end_time = ''
        }
      }
      var monday;
      var tuesday;
      var wednesday;
      var thursday;
      var friday;
      var saturday;
      var sunday;
      this.monday ? monday = 1 : monday = 2
      this.tuesday ? tuesday = 1 : tuesday = 2
      this.wednesday ? wednesday = 1 : wednesday = 2
      this.thursday ? thursday = 1 : thursday = 2
      this.friday ? friday = 1 : friday = 2
      this.saturday ? saturday = 1 : saturday = 2
      this.sunday ? sunday = 1 : sunday = 2
      if (monday == 2 && tuesday == 2 && wednesday == 2 && thursday == 2 && friday == 2 && saturday == 2 && sunday == 2) {
        this.$message({
          message: '请选择定时周期',
          type: 'warning'
        })
        return false;
      }
      if (start_time == '') {
        this.$message({
          message: '请选择时间',
          type: 'warning'
        })
        return false;
      }
      let params = {
        gid: this.row_value.id, type: this.timetype, state: this.issuetimeswitch, start_time: start_time, end_time: end_time, monday: monday,
        tuesday: tuesday, thursday: thursday, wednesday: wednesday, friday: friday, sunday: sunday, saturday: saturday, mid: this.$route.params.mid
      }

      this.$axios.post("/supplier/good/timed.up.and.down", params).then((res) => {
        if (res.data.code == 20000) {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.setissuedialog = false
        } else {
          this.$message({
            message: res.data.message,
            type: 'warning'
          })
        }
      })
    },
    sjtimes() {
      this.timetype = 1
      this.whtime = true
      if (this.issuetime == null) {
        this.whtime = false
      }
    },
    whtimes() {
      this.timetype = 2
      this.sjtime = true
      if (this.issuetimes == null) {
        this.sjtime = false
      }
    },
    //定时上架
    setautoissue(row) {
      this.row_value = row
      this.$axios.post("/supplier/good/get.timed.up.and.down", { gid: row.id, mid: this.$route.params.mid }).then((res) => {
        if (res.data.code == 20000) {
          let data = res.data.data
          data.monday == 2 ? this.monday = false : this.monday = true
          data.tuesday == 2 ? this.tuesday = false : this.tuesday = true
          data.wednesday == 2 ? this.wednesday = false : this.wednesday = true
          data.thursday == 2 ? this.thursday = false : this.thursday = true
          data.friday == 2 ? this.friday = false : this.friday = true
          data.saturday == 2 ? this.saturday = false : this.saturday = true
          data.sunday == 2 ? this.sunday = false : this.sunday = true
          if (data.type == 1) {
            this.issuetime = [data.start_time, data.end_time]
            this.sjtime = false
            this.whtime = true
            this.timetype = 1
            this.issuetimes = ['00:00:00', '23:59:59'];
          } else {
            this.issuetime = ['00:00:00', '23:59:59'];
            this.sjtime = true
            this.whtime = false
            this.timetype = 2
            this.issuetimes = [data.start_time, data.end_time]
          }
          this.issuetimeswitch = data.state
          this.setissuedialog = true
        }


      })
    },
    // 定时改价
    toupdate(row) {
      this.info_name = row.good_name
      this.row_info.gid = row.id;
      this.row_info.purchase_price = row.purchase_price;
      this.row_info.price = row.price;
      this.row_info.official_price = row.official_price;
      this.row_info.change_time = ''
      this.row_info.mid = this.$route.params.mid
      this.updatedialog = true
    },
    update_price() {
      if (this.row_info.change_time == '' || this.row_info.price == ' ') {
        this.$message({
          message: '请完整填写商品更新信息！',
          type: 'error',
          center: 'true',
        });
        return;
      }
      this.$axios.post('/supplier/good/timely.price.change', this.row_info).then((res) => {
        if (res.data.code == 20000) {
          this.$message({
            message: '定时改价设置成功',
            type: 'success',
            center: 'true',
          });
          this.updatedialog = false
          this.getlist(1)
        } else {
          this.$message({
            message: res.data.message,
            type: 'warning',
            center: 'true',
          });
          this.getlist(1)
        }
      })
    },
    pricechange(row) {
      let reg = /^([0-9][0-9]*)+(\.[0-9]{1,4})?$/
      if (!reg.test(row.price)) {
        this.$message({
          type: "warning",
          message: "请输入正确商品的销售价"
        })
        this.getlist()
      } else {
        if (parseFloat(row.price) == parseFloat(this.oldprice)) {
          this.getlist()
          return
        }
        this.$axios.post('/supplier/merchant/good.change.price', { gid: row.id, price: row.price, mid: this.$route.params.mid }).then(res => {
          if (res.data.code == 20000) {
            this.$message({
              type: "success",
              message: res.data.message
            })
            this.getlist()
          } else {
            this.$message({
              type: "warning",
              message: res.data.message
            })
            this.getlist()
          }
        })
      }
    },
    edit(row) {
      this.$router.push({ name: "Edit", params: { row: row, mid: this.$route.params.mid, a: this.$route.params.a, list: 4, id: this.$route.params.id, } })
    },
    resets() {
      this.Interfacename = ''
      this.currentPages = 1
      this.search()
    },
    current_changes(page) {
      this.currentPages = page
      this.search()
    },
    changing() {
      // console.log(this.radio);
      this.$axios.post("/supplier/good/merchant/good.switch.channel", { gid: this.id, change: this.radio, mid: this.$route.params.mid }).then((res) => {
        if (res.data.code == 20000) {
          this.getlist(1)
          this.$message({
            type: "success",
            message: res.data.message
          })
          this.dialogVisible = false
        } else {
          this.$message({
            type: "warning",
            message: res.data.message
          })
        }
      })
    },
    changein(row) {
      this.row_val = row
    },
    search() {
      this.$axios.post("/supplier/good/merchant/good.list.switch.channel", { gid: this.id, page: this.currentPages, limit: this.pagesizes, name: this.Interfacename, mid: this.$route.params.mid }).then((res) => {
        if (res.data.code == 20000) {
          this.InterfaceData = res.data.data.rows
          this.totals = res.data.data.rows.length
        } else {
          this.InterfaceData = []
          this.totals = []
        }
      })
    },
    changeload(row) {
      this.id = row.id
      this.$axios.post("/supplier/good/merchant/good.list.switch.channel", { gid: this.id, page: 1, limit: 10, mid: this.$route.params.mid }).then((res) => {
        if (res.data.code == 20000) {
          this.Interfacename = ''
          this.InterfaceData = res.data.data.rows
          this.radio = res.data.data.choose
          this.dialogVisible = true
          this.totals = res.data.data.rows.length
        } else {
          this.$message({
            type: 'warning',
            message: res.data.message
          })
        }
      })
    },
    goback(a) {
      if (a == 2) {
        this.$router.push({
          name: "Bread", params: {
            mid: this.$route.params.mid, name: this.$route.params.name, a: this.$route.params.b,
            b: this.$route.params.c, id: this.$route.params.pid
          }
        })
      } else {
        this.$router.push({ name: "Merchant_goods", params: { mid: this.$route.params.mid, name: this.$route.params.name } })
      }
    },
    reset() {
      this.currentPage = 1
      this.goodsname = ''
      this.goodscode = ''
      this.getlist()
    },
    current_change(page) {
      this.currentPage = page
      this.getlist()
    },
    getlist(page) {
      if (page != undefined) {
        this.currentPage = 1
      }
      this.$axios.post('/supplier/merchant/good.list', {
        mid: this.$route.params.mid, good_name: this.goodsname, coding: this.goodscode,
        brand_id: this.$route.params.id,
        limit: this.pagesize, page: this.currentPage
      })
        .then((res) => {
          if (res.data.code == 20000) {
            this.tableData = res.data.data.rows
            this.total = res.data.data.total
          } else {
            this.tableData = []
            this.total = 0
          }

        })
    }
  },

}
</script>

<style lang="less" scoped>
.title {
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  border-bottom: 1px solid gray;
  text-align: left;
  font-size: 16px;
}

.agent_search {
  width: 99%;
  height: 70px;
  border: 1px solid #ccc;
  margin-top: 10px;
  float: left;
  text-align: left;
  margin-left: 10px;
}

.agent_searchs {
  width: 100%;
  height: 70px;
  border: 1px solid #ccc;
  margin-top: 10px
}

.lab {
  font-size: 16px;
  margin: 20px 10px 10px 20px;
  display: inline-block
}

.goods_search {
  width: 220px;
  height: 25px;
  border-color: initial;
  margin-left: 3px
}

.goods_searchs {
  width: 40%;
  height: 25px;
  border-color: initial;
  margin-left: 3px
}

.timelist /deep/ .el-checkbox__input {
  display: none;
}

.timelist /deep/ .el-checkbox {
  margin-right: 8px;
}

.timelist /deep/ .el-checkbox-group {
  width: 630px;
  display: inline-block;
}
</style>